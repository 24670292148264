<template>
  <!-- hidden PageHeaderWrapper title demo -->
    <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
      <a-form @submit="handleSubmit" :form="form">
        <a-row class="form-row" :gutter="16">
          <a-col :lg="18" :md="18" :sm="24">
            <a-form-item
              label="父菜单"
              :labelCol="{lg: {span: 7}, sm: {span: 7}}"
              :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
              <a-select v-model="formData.pid" placeholder="请选择" style="width:180px">
                <a-select-option :value="0">零级菜单</a-select-option>
                <a-select-option
                  :value="item.id"
                  v-for="item in menuList"
                  :key="item.id"
                >{{ item.description }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="菜单名称"
              :labelCol="{lg: {span: 7}, sm: {span: 7}}"
              :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
              <a-input
                v-model="formData.description"
                placeholder="请输入菜单项" />
            </a-form-item>
            <a-form-item
              label="菜单值"
              :labelCol="{lg: {span: 7}, sm: {span: 7}}"
              :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
              <a-input
                v-model="formData.value"
                placeholder="请输入菜单项对应的数值" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          :wrapperCol="{ span: 24 }"
          style="text-align: center"
        >
          <a-button htmlType="submit" type="primary" @click="saveMenu">提交</a-button>
          <a-button style="margin-left: 8px" @click="back">返回</a-button>
        </a-form-item>
      </a-form>
    </a-card>
</template>

<script>
import { getMenuList, addMenu } from '@/api/basic/common'

export default {
  name: 'BaseForm',
  created () {
    this.getMenu()
  },
  data () {
    return {
      form: this.$form.createForm(this),
      // 表单数据
      formData: {
        description: '',
        level: '1',
        pid: 0,
        value: ''
      },
      // 查询参数
      menuParam: {
        menu: '零级菜单'
      },
      // 接口返回数据
      menuList: []
    }
  },
  methods: {
    // 接口请求
    getMenu () {
      getMenuList(this.menuParam).then(response => {
        this.menuList = response.data.list
      })
    },
    // 操作方法
    saveMenu () {
      if (this.formData.pid === '0') {
        this.formData.level = '1'
      } else {
        this.formData.level = '2'
      }
      addMenu(this.formData).then(response => {
        if (response.code === 0){
          this.$message.info('添加成功')
          this.$router.back()
        } else {
          this.$message.error('添加失败')
        }
      })
    },
    back () {
      this.$router.back()
    },
    // handler
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .a-hover:hover {
    cursor: pointer;
  }
</style>
